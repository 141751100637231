import {
  WHYUZ_FRONTEND_URL_APPROVAL_EXECUTION,
  WHYUZ_FRONTEND_URL_APPROVAL_EXECUTIONS,
  WHYUZ_FRONTEND_URL_APPROVAL_EXECUTION_ID,
  WHYUZ_FRONTEND_URL_APPROVAL_FLOW,
  WHYUZ_FRONTEND_URL_APPROVAL_FLOWS,
  WHYUZ_FRONTEND_URL_APPROVAL_FLOW_ID,
  WHYUZ_FRONTEND_URL_CAMPAIGN,
  WHYUZ_FRONTEND_URL_CAMPAIGNS,
  WHYUZ_FRONTEND_URL_CAMPAIGN_ID,
  WHYUZ_FRONTEND_URL_COLLABORATION,
  WHYUZ_FRONTEND_URL_COLLABORATIONS,
  WHYUZ_FRONTEND_URL_COLLABORATION_ID,
  WHYUZ_FRONTEND_URL_COLLABORATION_SUCCESS_CASE,
  WHYUZ_FRONTEND_URL_COLLABORATION_SUCCESS_CASE_ID,
  WHYUZ_FRONTEND_URL_CONTACT,
  WHYUZ_FRONTEND_URL_CONTACTS,
  WHYUZ_FRONTEND_URL_CONTACT_ID,
  WHYUZ_FRONTEND_URL_CONTENT,
  WHYUZ_FRONTEND_URL_CONTENTS,
  WHYUZ_FRONTEND_URL_CONTENT_ID,
  WHYUZ_FRONTEND_URL_CONTENT_TEMPLATE,
  WHYUZ_FRONTEND_URL_CONTENT_TEMPLATES,
  WHYUZ_FRONTEND_URL_CONTENT_TEMPLATE_ID,
  WHYUZ_FRONTEND_URL_CORPORATION,
  WHYUZ_FRONTEND_URL_CORPORATIONS,
  WHYUZ_FRONTEND_URL_CORPORATION_ADMINISTRATOR,
  WHYUZ_FRONTEND_URL_CORPORATION_ID,
  WHYUZ_FRONTEND_URL_CUSTOMER,
  WHYUZ_FRONTEND_URL_CUSTOMERS,
  WHYUZ_FRONTEND_URL_CUSTOMER_ID,
  WHYUZ_FRONTEND_URL_DATABASE,
  WHYUZ_FRONTEND_URL_DATABASES,
  WHYUZ_FRONTEND_URL_DATABASE_ID,
  WHYUZ_FRONTEND_URL_HELP,
  WHYUZ_FRONTEND_URL_HOME,
  WHYUZ_FRONTEND_URL_LOGIN,
  WHYUZ_FRONTEND_URL_MAIL_ACCOUNT,
  WHYUZ_FRONTEND_URL_MAIL_ACCOUNTS,
  WHYUZ_FRONTEND_URL_MAIL_ACCOUNT_ID,
  WHYUZ_FRONTEND_URL_MAIL_TEMPLATE,
  WHYUZ_FRONTEND_URL_MAIL_TEMPLATES,
  WHYUZ_FRONTEND_URL_MAIL_TEMPLATE_ID,
  WHYUZ_FRONTEND_URL_REWARD_TEMPLATE,
  WHYUZ_FRONTEND_URL_REWARD_TEMPLATES,
  WHYUZ_FRONTEND_URL_REWARD_TEMPLATE_ID,
  WHYUZ_FRONTEND_URL_SUCCESS_CASE,
  WHYUZ_FRONTEND_URL_SUCCESS_CASES,
  WHYUZ_FRONTEND_URL_SUCCESS_CASE_CONTENT_EDITOR,
  WHYUZ_FRONTEND_URL_SUCCESS_CASE_CONTENT_EDITOR_ID,
  WHYUZ_FRONTEND_URL_SUCCESS_CASE_ID,
  WHYUZ_FRONTEND_URL_SUCCESS_CASE_TEMPLATE,
  WHYUZ_FRONTEND_URL_SUCCESS_CASE_TEMPLATES,
  WHYUZ_FRONTEND_URL_SUCCESS_CASE_TEMPLATE_ID,
  WHYUZ_FRONTEND_URL_TENANT,
  WHYUZ_FRONTEND_URL_TENANTS,
  WHYUZ_FRONTEND_URL_TENANT_ID,
  WHYUZ_FRONTEND_URL_USER,
  WHYUZ_FRONTEND_URL_USERS,
  WHYUZ_FRONTEND_URL_USER_ID,
  WHYUZ_FRONTEND_URL_USER_LICENSE,
  WHYUZ_FRONTEND_URL_USER_LICENSES,
  WHYUZ_FRONTEND_URL_USER_LICENSE_ID,
  WHYUZ_FRONTEND_URL_USER_APPROVAL,
  WHYUZ_FRONTEND_URL_ASSIGNED_REWARD,
  WHYUZ_FRONTEND_URL_ASSIGNED_REWARD_ID,
  WHYUZ_FRONTEND_URL_ASSIGNED_REWARDS,
} from "./routes.ts";

export const ENDPOINT = {
  GRAPHQL: `${(import.meta.env.VITE_REACT_APP_BACKEND_API_URL as string) ?? "https://api.whyuz.com"}/graphql`,
  REST: (import.meta.env.VITE_REACT_APP_BACKEND_API_URL as string) ?? "https://api.whyuz.com",
  KEYCLOAK: (import.meta.env.VITE_REACT_APP_KEYCLOAK_API_URL as string) ?? "https://idm.whyuz.com",
  REACT_APP: (import.meta.env.VITE_REACT_APP_URL as string) ?? "https://app.whyuz.com",
  WEB_SOCKET: (import.meta.env.VITE_REACT_APP_BACKEND_WEB_SOCKET_URL as string) ?? "https://ws.whyuz.com/ws",
};

export const API_REST_ENDPOINT = {
  ASSETS: ENDPOINT.REST + "/assets",
};

export const WHYUZ_FRONTEND_URL = {
  HOME: WHYUZ_FRONTEND_URL_HOME,
  LOGIN: WHYUZ_FRONTEND_URL_LOGIN,
  HELP: WHYUZ_FRONTEND_URL_HELP,
  APPROVAL_FLOW: WHYUZ_FRONTEND_URL_APPROVAL_FLOW,
  APPROVAL_FLOW_ID: WHYUZ_FRONTEND_URL_APPROVAL_FLOW_ID,
  APPROVAL_FLOWS: WHYUZ_FRONTEND_URL_APPROVAL_FLOWS,
  APPROVAL_EXECUTION: WHYUZ_FRONTEND_URL_APPROVAL_EXECUTION,
  APPROVAL_EXECUTIONS: WHYUZ_FRONTEND_URL_APPROVAL_EXECUTIONS,
  APPROVAL_EXECUTION_ID: WHYUZ_FRONTEND_URL_APPROVAL_EXECUTION_ID,

  USER_APPROVAL: WHYUZ_FRONTEND_URL_USER_APPROVAL,

  CAMPAIGN: WHYUZ_FRONTEND_URL_CAMPAIGN,
  CAMPAIGN_ID: WHYUZ_FRONTEND_URL_CAMPAIGN_ID,
  CAMPAIGNS: WHYUZ_FRONTEND_URL_CAMPAIGNS,
  COLLABORATION: WHYUZ_FRONTEND_URL_COLLABORATION,
  COLLABORATION_ID: WHYUZ_FRONTEND_URL_COLLABORATION_ID,
  COLLABORATIONS: WHYUZ_FRONTEND_URL_COLLABORATIONS,
  COLLABORATION_SUCCESS_CASE: WHYUZ_FRONTEND_URL_COLLABORATION_SUCCESS_CASE,
  COLLABORATION_SUCCESS_CASE_ID: WHYUZ_FRONTEND_URL_COLLABORATION_SUCCESS_CASE_ID,
  CONTACT: WHYUZ_FRONTEND_URL_CONTACT,
  CONTACT_ID: WHYUZ_FRONTEND_URL_CONTACT_ID,
  CONTACTS: WHYUZ_FRONTEND_URL_CONTACTS,
  CONTENT: WHYUZ_FRONTEND_URL_CONTENT,
  CONTENT_ID: WHYUZ_FRONTEND_URL_CONTENT_ID,
  CONTENTS: WHYUZ_FRONTEND_URL_CONTENTS,
  CONTENT_TEMPLATE: WHYUZ_FRONTEND_URL_CONTENT_TEMPLATE,
  CONTENT_TEMPLATE_ID: WHYUZ_FRONTEND_URL_CONTENT_TEMPLATE_ID,
  CONTENT_TEMPLATES: WHYUZ_FRONTEND_URL_CONTENT_TEMPLATES,
  CUSTOMER: WHYUZ_FRONTEND_URL_CUSTOMER,
  CUSTOMER_ID: WHYUZ_FRONTEND_URL_CUSTOMER_ID,
  CUSTOMERS: WHYUZ_FRONTEND_URL_CUSTOMERS,
  CORPORATION: WHYUZ_FRONTEND_URL_CORPORATION,
  CORPORATION_ID: WHYUZ_FRONTEND_URL_CORPORATION_ID,
  CORPORATIONS: WHYUZ_FRONTEND_URL_CORPORATIONS,
  CORPORATION_ADMINISTRATOR: WHYUZ_FRONTEND_URL_CORPORATION_ADMINISTRATOR,
  DATABASE: WHYUZ_FRONTEND_URL_DATABASE,
  DATABASE_ID: WHYUZ_FRONTEND_URL_DATABASE_ID,
  DATABASES: WHYUZ_FRONTEND_URL_DATABASES,
  MAIL_ACCOUNT: WHYUZ_FRONTEND_URL_MAIL_ACCOUNT,
  MAIL_ACCOUNT_ID: WHYUZ_FRONTEND_URL_MAIL_ACCOUNT_ID,
  MAIL_ACCOUNTS: WHYUZ_FRONTEND_URL_MAIL_ACCOUNTS,
  MAIL_TEMPLATE: WHYUZ_FRONTEND_URL_MAIL_TEMPLATE,
  MAIL_TEMPLATE_ID: WHYUZ_FRONTEND_URL_MAIL_TEMPLATE_ID,
  MAIL_TEMPLATES: WHYUZ_FRONTEND_URL_MAIL_TEMPLATES,
  REWARD_TEMPLATE: WHYUZ_FRONTEND_URL_REWARD_TEMPLATE,
  REWARD_TEMPLATE_ID: WHYUZ_FRONTEND_URL_REWARD_TEMPLATE_ID,
  REWARD_TEMPLATES: WHYUZ_FRONTEND_URL_REWARD_TEMPLATES,
  ASSIGNED_REWARD: WHYUZ_FRONTEND_URL_ASSIGNED_REWARD,
  ASSIGNED_REWARD_ID: WHYUZ_FRONTEND_URL_ASSIGNED_REWARD_ID,
  ASSIGNED_REWARDS: WHYUZ_FRONTEND_URL_ASSIGNED_REWARDS,
  SUCCESS_CASE_CONTENT_EDITOR: WHYUZ_FRONTEND_URL_SUCCESS_CASE_CONTENT_EDITOR,
  SUCCESS_CASE_CONTENT_EDITOR_ID: WHYUZ_FRONTEND_URL_SUCCESS_CASE_CONTENT_EDITOR_ID,
  SUCCESS_CASE_TEMPLATE: WHYUZ_FRONTEND_URL_SUCCESS_CASE_TEMPLATE,
  SUCCESS_CASE_TEMPLATE_ID: WHYUZ_FRONTEND_URL_SUCCESS_CASE_TEMPLATE_ID,
  SUCCESS_CASE_TEMPLATES: WHYUZ_FRONTEND_URL_SUCCESS_CASE_TEMPLATES,
  SUCCESS_CASE: WHYUZ_FRONTEND_URL_SUCCESS_CASE,
  SUCCESS_CASE_ID: WHYUZ_FRONTEND_URL_SUCCESS_CASE_ID,
  SUCCESS_CASES: WHYUZ_FRONTEND_URL_SUCCESS_CASES,
  TENANT: WHYUZ_FRONTEND_URL_TENANT,
  TENANT_ID: WHYUZ_FRONTEND_URL_TENANT_ID,
  TENANTS: WHYUZ_FRONTEND_URL_TENANTS,
  USER: WHYUZ_FRONTEND_URL_USER,
  USER_ID: WHYUZ_FRONTEND_URL_USER_ID,
  USERS: WHYUZ_FRONTEND_URL_USERS,
  USER_LICENSE: WHYUZ_FRONTEND_URL_USER_LICENSE,
  USER_LICENSE_ID: WHYUZ_FRONTEND_URL_USER_LICENSE_ID,
  USER_LICENSES: WHYUZ_FRONTEND_URL_USER_LICENSES,
};

export enum WHYUZ_URL_PARAMETERS {
  PARENT_ID = "pid",
  ID = "id",
  ACTION = "ac",
  TOKEN = "token",
  TENANT_ID = "tid",
  TAB = "tb",
  SEARCH = "q",
  USE_CAMERA_OR_UPLOAD_FILE = "cou",
}

export enum WHYUZ_URL_ACTIONS {
  VIEW = "vw",
  EDIT = "ed",
  ADD = "ad",
}

export type UrlParameter = {
  key: WHYUZ_URL_PARAMETERS;
  value: string;
};

const getStringForUrlParameter = (param: UrlParameter): string => param.key + "=" + param.value;
const getStringForUrlParameters = (params: UrlParameter[]): string => {
  let urlString = "";
  for (const currentParam of params) {
    const prefix = urlString !== "" ? "&&" : "?";
    urlString = urlString.concat(prefix + getStringForUrlParameter(currentParam));
  }
  return urlString;
};

const getParentIdUrlParameter = (parentId: string): UrlParameter => {
  return { key: WHYUZ_URL_PARAMETERS.PARENT_ID, value: parentId };
};

const getTenantIdUrlParameter = (tenantId: string): UrlParameter => {
  return { key: WHYUZ_URL_PARAMETERS.TENANT_ID, value: tenantId };
};

const getTokenUrlParameter = (token: string): UrlParameter => {
  return { key: WHYUZ_URL_PARAMETERS.TOKEN, value: token };
};

const getActionUrlParameter = (action: WHYUZ_URL_ACTIONS): UrlParameter | undefined => {
  switch (action) {
    case WHYUZ_URL_ACTIONS.ADD:
      return { key: WHYUZ_URL_PARAMETERS.ACTION, value: WHYUZ_URL_ACTIONS.ADD };
    case WHYUZ_URL_ACTIONS.EDIT:
      return {
        key: WHYUZ_URL_PARAMETERS.ACTION,
        value: WHYUZ_URL_ACTIONS.EDIT,
      };
    default:
      return undefined;
  }
};
const getAddViewEditEntityParameters = (
  action: WHYUZ_URL_ACTIONS,
  urlAdditionalParams: UrlParameter[],
  id?: string,
) => {
  const parameters = [...urlAdditionalParams];
  const actionParameter = getActionUrlParameter(action);
  if (actionParameter) {
    parameters.push(actionParameter);
  }
  return id ? `/${id}${getStringForUrlParameters(parameters)}` : getStringForUrlParameters(parameters);
};

export const WHYUZ_DYNAMIC_FRONTEND_URL = {
  approvalFlowExecution: {
    view: (id: string) =>
      WHYUZ_FRONTEND_URL.APPROVAL_EXECUTION + getAddViewEditEntityParameters(WHYUZ_URL_ACTIONS.VIEW, [], id),
    edit: (id: string) =>
      WHYUZ_FRONTEND_URL.APPROVAL_EXECUTION + getAddViewEditEntityParameters(WHYUZ_URL_ACTIONS.EDIT, [], id),
  },
  approvalFlow: {
    view: (id: string) =>
      WHYUZ_FRONTEND_URL.APPROVAL_FLOW + getAddViewEditEntityParameters(WHYUZ_URL_ACTIONS.VIEW, [], id),
    edit: (id: string) =>
      WHYUZ_FRONTEND_URL.APPROVAL_FLOW + getAddViewEditEntityParameters(WHYUZ_URL_ACTIONS.EDIT, [], id),
  },

  userApproval: {
    view: (token: string) =>
      WHYUZ_FRONTEND_URL.USER_APPROVAL +
      getAddViewEditEntityParameters(WHYUZ_URL_ACTIONS.VIEW, [getTokenUrlParameter(token)]),
  },

  rewardsTemplates: {
    add: () => WHYUZ_FRONTEND_URL.REWARD_TEMPLATE + getAddViewEditEntityParameters(WHYUZ_URL_ACTIONS.ADD, []),
    view: (id: string) =>
      WHYUZ_FRONTEND_URL.REWARD_TEMPLATE + getAddViewEditEntityParameters(WHYUZ_URL_ACTIONS.VIEW, [], id),
    edit: (id: string) =>
      WHYUZ_FRONTEND_URL.REWARD_TEMPLATE + getAddViewEditEntityParameters(WHYUZ_URL_ACTIONS.EDIT, [], id),
  },
  campaign: {
    add: () => WHYUZ_FRONTEND_URL.CAMPAIGN + getAddViewEditEntityParameters(WHYUZ_URL_ACTIONS.ADD, []),
    view: (id: string) => WHYUZ_FRONTEND_URL.CAMPAIGN + getAddViewEditEntityParameters(WHYUZ_URL_ACTIONS.VIEW, [], id),
    edit: (id: string) => WHYUZ_FRONTEND_URL.CAMPAIGN + getAddViewEditEntityParameters(WHYUZ_URL_ACTIONS.EDIT, [], id),
  },
  collaborationSuccessCase: {
    view: (id: string, tenantId: string) =>
      WHYUZ_FRONTEND_URL.COLLABORATION_SUCCESS_CASE +
      getAddViewEditEntityParameters(WHYUZ_URL_ACTIONS.VIEW, [getTenantIdUrlParameter(tenantId)], id),
  },
  contact: {
    add: (customerId?: string) =>
      WHYUZ_FRONTEND_URL.CONTACT +
      getAddViewEditEntityParameters(WHYUZ_URL_ACTIONS.ADD, customerId ? [getParentIdUrlParameter(customerId)] : []),
    view: (id: string) => WHYUZ_FRONTEND_URL.CONTACT + getAddViewEditEntityParameters(WHYUZ_URL_ACTIONS.VIEW, [], id),
    edit: (id: string) => WHYUZ_FRONTEND_URL.CONTACT + getAddViewEditEntityParameters(WHYUZ_URL_ACTIONS.EDIT, [], id),
  },
  content: {
    view: (id: string) => WHYUZ_FRONTEND_URL.CONTENT + getAddViewEditEntityParameters(WHYUZ_URL_ACTIONS.VIEW, [], id),
    edit: (id: string) => WHYUZ_FRONTEND_URL.CONTENT + getAddViewEditEntityParameters(WHYUZ_URL_ACTIONS.EDIT, [], id),
  },
  contentTemplate: {
    add: () => WHYUZ_FRONTEND_URL.CONTENT_TEMPLATE + getAddViewEditEntityParameters(WHYUZ_URL_ACTIONS.ADD, []),
    view: (id: string) =>
      WHYUZ_FRONTEND_URL.CONTENT_TEMPLATE + getAddViewEditEntityParameters(WHYUZ_URL_ACTIONS.VIEW, [], id),
    edit: (id: string) =>
      WHYUZ_FRONTEND_URL.CONTENT_TEMPLATE + getAddViewEditEntityParameters(WHYUZ_URL_ACTIONS.EDIT, [], id),
  },
  corporation: {
    add: () => WHYUZ_FRONTEND_URL.CORPORATION + getAddViewEditEntityParameters(WHYUZ_URL_ACTIONS.ADD, []),
    view: (id: string) =>
      WHYUZ_FRONTEND_URL.CORPORATION + getAddViewEditEntityParameters(WHYUZ_URL_ACTIONS.VIEW, [], id),
    edit: (id: string) =>
      WHYUZ_FRONTEND_URL.CORPORATION + getAddViewEditEntityParameters(WHYUZ_URL_ACTIONS.EDIT, [], id),
  },
  corporationAdministrator: {
    add: (corporationId?: string) =>
      WHYUZ_FRONTEND_URL.CORPORATION_ADMINISTRATOR +
      getAddViewEditEntityParameters(
        WHYUZ_URL_ACTIONS.ADD,
        corporationId ? [getParentIdUrlParameter(corporationId)] : [],
      ),
  },
  customer: {
    add: () => WHYUZ_FRONTEND_URL.CUSTOMER + getAddViewEditEntityParameters(WHYUZ_URL_ACTIONS.ADD, []),
    view: (id: string) => WHYUZ_FRONTEND_URL.CUSTOMER + getAddViewEditEntityParameters(WHYUZ_URL_ACTIONS.VIEW, [], id),
    edit: (id: string) => WHYUZ_FRONTEND_URL.CUSTOMER + getAddViewEditEntityParameters(WHYUZ_URL_ACTIONS.EDIT, [], id),
  },
  database: {
    add: () => WHYUZ_FRONTEND_URL.DATABASE + getAddViewEditEntityParameters(WHYUZ_URL_ACTIONS.ADD, []),
    view: (id: string) => WHYUZ_FRONTEND_URL.DATABASE + getAddViewEditEntityParameters(WHYUZ_URL_ACTIONS.VIEW, [], id),
    edit: (id: string) => WHYUZ_FRONTEND_URL.DATABASE + getAddViewEditEntityParameters(WHYUZ_URL_ACTIONS.EDIT, [], id),
  },
  mailAccount: {
    add: () => WHYUZ_FRONTEND_URL.MAIL_ACCOUNT + getAddViewEditEntityParameters(WHYUZ_URL_ACTIONS.ADD, []),
    view: (id: string) =>
      WHYUZ_FRONTEND_URL.MAIL_ACCOUNT + getAddViewEditEntityParameters(WHYUZ_URL_ACTIONS.VIEW, [], id),
    edit: (id: string) =>
      WHYUZ_FRONTEND_URL.MAIL_ACCOUNT + getAddViewEditEntityParameters(WHYUZ_URL_ACTIONS.EDIT, [], id),
  },
  mailTemplate: {
    view: (id: string) =>
      WHYUZ_FRONTEND_URL.MAIL_TEMPLATE + getAddViewEditEntityParameters(WHYUZ_URL_ACTIONS.VIEW, [], id),
    edit: (id: string) =>
      WHYUZ_FRONTEND_URL.MAIL_TEMPLATE + getAddViewEditEntityParameters(WHYUZ_URL_ACTIONS.EDIT, [], id),
  },

  assignedReward: {
    add: () => WHYUZ_FRONTEND_URL.ASSIGNED_REWARD + getAddViewEditEntityParameters(WHYUZ_URL_ACTIONS.ADD, []),
    view: (id: string) =>
      WHYUZ_FRONTEND_URL.ASSIGNED_REWARD + getAddViewEditEntityParameters(WHYUZ_URL_ACTIONS.VIEW, [], id),
    edit: (id: string) =>
      WHYUZ_FRONTEND_URL.ASSIGNED_REWARD + getAddViewEditEntityParameters(WHYUZ_URL_ACTIONS.EDIT, [], id),
  },

  successCase: {
    view: (id: string) =>
      WHYUZ_FRONTEND_URL.SUCCESS_CASE + getAddViewEditEntityParameters(WHYUZ_URL_ACTIONS.VIEW, [], id),
    edit: (id: string) =>
      WHYUZ_FRONTEND_URL.SUCCESS_CASE + getAddViewEditEntityParameters(WHYUZ_URL_ACTIONS.EDIT, [], id),
  },
  successCaseTemplate: {
    view: (id: string) =>
      WHYUZ_FRONTEND_URL.SUCCESS_CASE_TEMPLATE + getAddViewEditEntityParameters(WHYUZ_URL_ACTIONS.VIEW, [], id),
    edit: (id: string) =>
      WHYUZ_FRONTEND_URL.SUCCESS_CASE_TEMPLATE + getAddViewEditEntityParameters(WHYUZ_URL_ACTIONS.EDIT, [], id),
  },
  successCaseContentEditor: {
    view: (id: string, tenantId: string) =>
      WHYUZ_FRONTEND_URL.SUCCESS_CASE_CONTENT_EDITOR +
      getAddViewEditEntityParameters(WHYUZ_URL_ACTIONS.VIEW, [getTenantIdUrlParameter(tenantId)], id),
  },
  tenant: {
    add: (corporationId?: string) =>
      WHYUZ_FRONTEND_URL.TENANT +
      getAddViewEditEntityParameters(
        WHYUZ_URL_ACTIONS.ADD,
        corporationId ? [getParentIdUrlParameter(corporationId)] : [],
      ),
    view: (id: string) => WHYUZ_FRONTEND_URL.TENANT + getAddViewEditEntityParameters(WHYUZ_URL_ACTIONS.VIEW, [], id),
    edit: (id: string) => WHYUZ_FRONTEND_URL.TENANT + getAddViewEditEntityParameters(WHYUZ_URL_ACTIONS.EDIT, [], id),
  },
  userLicense: {
    add: () => WHYUZ_FRONTEND_URL.USER_LICENSE + getAddViewEditEntityParameters(WHYUZ_URL_ACTIONS.ADD, []),
    view: (id: string) =>
      WHYUZ_FRONTEND_URL.USER_LICENSE + getAddViewEditEntityParameters(WHYUZ_URL_ACTIONS.VIEW, [], id),
    edit: (id: string) =>
      WHYUZ_FRONTEND_URL.USER_LICENSE + getAddViewEditEntityParameters(WHYUZ_URL_ACTIONS.EDIT, [], id),
  },
  userTenantRole: {
    add: (tenantId?: string) =>
      WHYUZ_FRONTEND_URL.USER +
      getAddViewEditEntityParameters(WHYUZ_URL_ACTIONS.ADD, tenantId ? [getParentIdUrlParameter(tenantId)] : []),
    view: (id: string) => WHYUZ_FRONTEND_URL.USER + getAddViewEditEntityParameters(WHYUZ_URL_ACTIONS.VIEW, [], id),
    edit: (id: string) => WHYUZ_FRONTEND_URL.USER + getAddViewEditEntityParameters(WHYUZ_URL_ACTIONS.EDIT, [], id),
  },
};
