import { DEFAULT_LOCALE, resources } from "@whyuz/data";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

declare module "i18next" {
  interface CustomTypeOptions {
    defaultNS: "common";
    returnNull: false;
    fallbackLng: "en";
    resources: typeof resources.en;
  }
}

// INSTRUCTIONS to add a new language:
// 1.- Create a new folder in src/data/locales with the short name of the language locale (en for english, es for spanish)
// 2.- Copy all the files in the src/data/locales/en folder to the new folder
// 3.- In the new language folder: change the translations to the new language editing each json file
// 4.- In the new langauge folder: change the resources.ts file "export const en = ..." to export the short name of the language (for french would be "export const fr = ...")

// INSTRUCTIONS to add a new translation json file to the existing ones:
// 1.- Add the json file in each language folder (as example for english src/data/locales/en) and update in the same folder the language resources.ts file
// 2.- To support type detection (typescript) add in i18next.d.ts the new file just one time (in english by default)

// i18n automatic initialization when this file is imported (suggested to be done in Index.tsx component)
// detect user language
// learn more: https://github.com/i18next/i18next-browser-languageDetector
// eslint-disable-next-line @typescript-eslint/no-floating-promises
i18n
  .use(LanguageDetector)
  // .use(Backend) // remove resources and move to public locales (minute 4:00 https://storybook.js.org/blog/internationalize-components-with-storybook/)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources, // Not needed with use(Backend): lazy load of translations
    defaultNS: "common",
    returnNull: false,
    debug: process.env.NODE_ENV === "development",
    fallbackLng: DEFAULT_LOCALE,
    react: {
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ["br", "strong", "i", "b"],
    },
  });

export default i18n;
