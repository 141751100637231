import hexRgb from 'hex-rgb';

const hex2Rgba = (color: string) => {
  const { red: r, green: g, blue: b, alpha: a } = hexRgb(color);
  return { r, g, b, a };
};

export const COLOROBJECTSHEX = [
  { id: 'black', hex: '#000000' },
  { id: 'darkgray4', hex: '#434343' },
  { id: 'darkgray3', hex: '#666666' },
  { id: 'darkgray2', hex: '#999999' },
  { id: 'darkgray1', hex: '#b7b7b7' },
  { id: 'gray', hex: '#cccccc' },
  { id: 'lightgray1', hex: '#d9d9d9' },
  { id: 'lightgray2', hex: '#efefef' },
  { id: 'lightgray3', hex: '#f3f3f3' },
  { id: 'white', hex: '#ffffff' },
  { id: 'redberry', hex: '#980000' },
  { id: 'red', hex: '#ff0000' },
  { id: 'orange', hex: '#ff9900' },
  { id: 'yellow', hex: '#ffff00' },
  { id: 'green', hex: '#00ff00' },
  { id: 'cyan', hex: '#00ffff' },
  { id: 'cornflowerblue', hex: '#4a86e8' },
  { id: 'blue', hex: '#0000ff' },
  { id: 'purple', hex: '#9900ff' },
  { id: 'magenta', hex: '#ff00ff' },
  { id: 'lightredberry3', hex: '#e6b8af' },
  { id: 'lightred3', hex: '#f4cccc' },
  { id: 'lightorange3', hex: '#fce5cd' },
  { id: 'lightyellow3', hex: '#fff2cc' },
  { id: 'lightgreen3', hex: '#d9ead3' },
  { id: 'lightcyan3', hex: '#d0e0e3' },
  { id: 'lightcornflowerblue3', hex: '#c9daf8' },
  { id: 'lightblue3', hex: '#cfe2f3' },
  { id: 'lightpurple3', hex: '#d9d2e9' },
  { id: 'lightmagenta3', hex: '#ead1dc' },
  { id: 'lightredberry2', hex: '#dd7e6b' },
  { id: 'lightred2', hex: '#ea9999' },
  { id: 'lightorange2', hex: '#f9cb9c' },
  { id: 'lightyellow2', hex: '#ffe599' },
  { id: 'lightgreen2', hex: '#b6d7a8' },
  { id: 'lightcyan2', hex: '#a2c4c9' },
  { id: 'lightcornflowerblue2', hex: '#a4c2f4' },
  { id: 'lightblue2', hex: '#9fc5e8' },
  { id: 'lightpurple2', hex: '#b4a7d6' },
  { id: 'lightmagenta2', hex: '#d5a6bd' },
  { id: 'lightredberry1', hex: '#cc4125' },
  { id: 'lightred1', hex: '#e06666' },
  { id: 'lightorange1', hex: '#f6b26b' },
  { id: 'lightyellow1', hex: '#ffd966' },
  { id: 'lightgreen1', hex: '#93c47d' },
  { id: 'lightcyan1', hex: '#76a5af' },
  { id: 'lightcornflowerblue1', hex: '#6d9eeb' },
  { id: 'lightblue1', hex: '#6fa8dc' },
  { id: 'lightpurple1', hex: '#8e7cc3' },
  { id: 'lightmagenta1', hex: '#c27ba0' },
  { id: 'darkredberry1', hex: '#a61c00' },
  { id: 'darkred1', hex: '#cc0000' },
  { id: 'darkorange1', hex: '#e69138' },
  { id: 'darkyellow1', hex: '#f1c232' },
  { id: 'darkgreen1', hex: '#6aa84f' },
  { id: 'darkcyan1', hex: '#45818e' },
  { id: 'darkcornflowerblue1', hex: '#3c78d8' },
  { id: 'darkblue1', hex: '#3d85c6' },
  { id: 'darkpurple1', hex: '#674ea7' },
  { id: 'darkmagenta1', hex: '#a64d79' },
  { id: 'darkredberry2', hex: '#85200c' },
  { id: 'darkred2', hex: '#990000' },
  { id: 'darkorange2', hex: '#b45f06' },
  { id: 'darkyellow2', hex: '#bf9000' },
  { id: 'darkgreen2', hex: '#38761d' },
  { id: 'darkcyan2', hex: '#134f5c' },
  { id: 'darkcornflowerblue2', hex: '#1155cc' },
  { id: 'darkblue2', hex: '#0b5394' },
  { id: 'darkpurple2', hex: '#351c75' },
  { id: 'darkmagenta2', hex: '#741b47' },
  { id: 'darkredberry3', hex: '#5b0f00' },
  { id: 'darkred3', hex: '#660000' },
  { id: 'darkorange3', hex: '#783f04' },
  { id: 'darkyellow3', hex: '#7f6000' },
  { id: 'darkgreen3', hex: '#274e13' },
  { id: 'darkcyan3', hex: '#0c343d' },
  { id: 'darkcornflowerblue3', hex: '#1c4587' },
  { id: 'darkblue3', hex: '#073763' },
  { id: 'darkpurple3', hex: '#20124d' },
  { id: 'darkmagenta3', hex: '#4c1130' },
];

export const COLOROBJECTSRGB = COLOROBJECTSHEX.map((color) => ({
  id: color.id,
  rgba: hex2Rgba(color.hex),
}));

export const COLORSHEX = {
  black: '#000000',
  darkgray4: '#434343',
  darkgray3: '#666666',
  darkgray2: '#999999',
  darkgray1: '#b7b7b7',
  gray: '#cccccc',
  lightgray1: '#d9d9d9',
  lightgray2: '#efefef',
  lightgray3: '#f3f3f3',
  white: '#ffffff',
  redberry: '#980000',
  red: '#ff0000',
  orange: '#ff9900',
  yellow: '#ffff00',
  green: '#00ff00',
  cyan: '#00ffff',
  cornflowerblue: '#4a86e8',
  blue: '#0000ff',
  purple: '#9900ff',
  magenta: '#ff00ff',
  lightredberry3: '#e6b8af',
  lightred3: '#f4cccc',
  lightorange3: '#fce5cd',
  lightyellow3: '#fff2cc',
  lightgreen3: '#d9ead3',
  lightcyan3: '#d0e0e3',
  lightcornflowerblue3: '#c9daf8',
  lightblue3: '#cfe2f3',
  lightpurple3: '#d9d2e9',
  lightmagenta3: '#ead1dc',
  lightredberry2: '#dd7e6b',
  lightred2: '#ea9999',
  lightorange2: '#f9cb9c',
  lightyellow2: '#ffe599',
  lightgreen2: '#b6d7a8',
  lightcyan2: '#a2c4c9',
  lightcornflowerblue2: '#a4c2f4',
  lightblue2: '#9fc5e8',
  lightpurple2: '#b4a7d6',
  lightmagenta2: '#d5a6bd',
  lightredberry1: '#cc4125',
  lightred1: '#e06666',
  lightorange1: '#f6b26b',
  lightyellow1: '#ffd966',
  lightgreen1: '#93c47d',
  lightcyan1: '#76a5af',
  lightcornflowerblue1: '#6d9eeb',
  lightblue1: '#6fa8dc',
  lightpurple1: '#8e7cc3',
  lightmagenta1: '#c27ba0',
  darkredberry1: '#a61c00',
  darkred1: '#cc0000',
  darkorange1: '#e69138',
  darkyellow1: '#f1c232',
  darkgreen1: '#6aa84f',
  darkcyan1: '#45818e',
  darkcornflowerblue1: '#3c78d8',
  darkblue1: '#3d85c6',
  darkpurple1: '#674ea7',
  darkmagenta1: '#a64d79',
  darkredberry2: '#85200c',
  darkred2: '#990000',
  darkorange2: '#b45f06',
  darkyellow2: '#bf9000',
  darkgreen2: '#38761d',
  darkcyan2: '#134f5c',
  darkcornflowerblue2: '#1155cc',
  darkblue2: '#0b5394',
  darkpurple2: '#351c75',
  darkmagenta2: '#741b47',
  darkredberry3: '#5b0f00',
  darkred3: '#660000',
  darkorange3: '#783f04',
  darkyellow3: '#7f6000',
  darkgreen3: '#274e13',
  darkcyan3: '#0c343d',
  darkcornflowerblue3: '#1c4587',
  darkblue3: '#073763',
  darkpurple3: '#20124d',
  darkmagenta3: '#4c1130',
};
