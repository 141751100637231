import { DEFAULT_LOCALE, resources } from "@whyuz/data";
import i18n from "i18next";

export const getCurrentLanguage = (): string => {
  return i18n.language;
};

export const getAvailableLanguages = (): string[] => {
  return Object.keys(resources);
};

export const isLocaleSupported = (locale: string): boolean => Object.keys(resources).indexOf(locale) >= 0;

export const getLocaleSupported = (locale: string): string => {
  // Search the full text locale
  if (isLocaleSupported(locale)) {
    return locale;
  }

  // Search the short text locale
  if (locale.length >= 2) {
    const shortLocale = locale.substring(0, 2);
    if (isLocaleSupported(shortLocale)) {
      return shortLocale;
    }
  }

  return DEFAULT_LOCALE;
};
