export enum FILE_EXTENSION {
  "7z" = "7z",
  aac = "aac",
  arj = "arj",
  avi = "avi",
  bin = "bin",
  css = "css",
  csv = "csv",
  doc = "doc",
  docx = "docx",
  eot = "eot",
  flv = "flv",
  gif = "gif",
  gz = "gz",
  gzip = "gzip",
  html = "html",
  ico = "ico",
  ics = "ics",
  jar = "jar",
  jpg = "jpg",
  js = "js",
  json = "json",
  midi = "midi",
  mkv = "mkv",
  mov = "mov",
  mp3 = "mp3",
  mpg = "mpg",
  mpeg = "mpeg",
  mpkg = "mpkg",
  mp4 = "mp4",
  odp = "odp",
  ods = "ods",
  odt = "odt",
  oga = "oga",
  ogv = "ogv",
  ogx = "ogx",
  opus = "opus",
  otf = "otf",
  pdf = "pdf",
  png = "png",
  php = "php",
  ppt = "ppt",
  pptx = "pptx",
  rar = "rar",
  rtf = "rtf",
  sh = "sh",
  svg = "svg",
  swf = "swf",
  tar = "tar",
  tiff = "tiff",
  ts = "ts",
  ttf = "ttf",
  txt = "txt",
  vsd = "vsd",
  wav = "wav",
  weba = "weba",
  webm = "webm",
  webp = "webp",
  wmv = "wmv",
  woff = "woff",
  woff2 = "woff2",
  xhtml = "xhtml",
  xls = "xls",
  xlsx = "xlsx",
  xml = "xml",
  zip = "zip",
}

export const FILE_EXTENSION_MIME_TYPE: Record<FILE_EXTENSION, string> = {
  [FILE_EXTENSION["7z"]]: "application/x-7z-compressed",
  [FILE_EXTENSION.aac]: "audio/aac",
  [FILE_EXTENSION.arj]: "application/x-arj-compressed",
  [FILE_EXTENSION.avi]: "video/x-msvideo",
  [FILE_EXTENSION.bin]: "application/octet-stream",
  [FILE_EXTENSION.css]: "text/css",
  [FILE_EXTENSION.csv]: "text/csv",
  [FILE_EXTENSION.doc]: "application/msword",
  [FILE_EXTENSION.docx]: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  [FILE_EXTENSION.eot]: "application/vnd.ms-fontobject",
  [FILE_EXTENSION.flv]: "video/x-flv",
  [FILE_EXTENSION.gif]: "image/gif",
  [FILE_EXTENSION.gz]: "application/gzip",
  [FILE_EXTENSION.gzip]: "application/gzip",
  [FILE_EXTENSION.html]: "text/html",
  [FILE_EXTENSION.ico]: "image/vnd.microsoft.icon",
  [FILE_EXTENSION.ics]: "text/calendar",
  [FILE_EXTENSION.jar]: "application/java-archive",
  [FILE_EXTENSION.jpg]: "image/jpeg",
  [FILE_EXTENSION.js]: "text/javascript",
  [FILE_EXTENSION.json]: "application/json",
  [FILE_EXTENSION.midi]: "audio/midi",
  [FILE_EXTENSION.mkv]: "video/x-matroska",
  [FILE_EXTENSION.mov]: "video/quicktime",
  [FILE_EXTENSION.mp3]: "audio/mpeg",
  [FILE_EXTENSION.mpg]: "video/mpeg",
  [FILE_EXTENSION.mpeg]: "video/mpeg",
  [FILE_EXTENSION.mpkg]: "application/vnd.apple.installer+xml",
  [FILE_EXTENSION.mp4]: "video/mp4",
  [FILE_EXTENSION.odp]: "application/vnd.oasis.opendocument.presentation",
  [FILE_EXTENSION.ods]: "application/vnd.oasis.opendocument.spreadsheet",
  [FILE_EXTENSION.odt]: "application/vnd.oasis.opendocument.text",
  [FILE_EXTENSION.oga]: "audio/ogg",
  [FILE_EXTENSION.ogv]: "video/ogg",
  [FILE_EXTENSION.ogx]: "application/ogg",
  [FILE_EXTENSION.opus]: "audio/opus",
  [FILE_EXTENSION.otf]: "font/otf",
  [FILE_EXTENSION.pdf]: "application/pdf",
  [FILE_EXTENSION.png]: "image/png",
  [FILE_EXTENSION.php]: "application/x-httpd-php",
  [FILE_EXTENSION.ppt]: "application/vnd.ms-powerpoint",
  [FILE_EXTENSION.pptx]: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  [FILE_EXTENSION.rar]: "application/vnd.rar",
  [FILE_EXTENSION.rtf]: "application/rtf",
  [FILE_EXTENSION.sh]: "application/x-sh",
  [FILE_EXTENSION.svg]: "image/svg+xml",
  [FILE_EXTENSION.swf]: "application/x-shockwave-flash",
  [FILE_EXTENSION.tar]: "application/x-tar",
  [FILE_EXTENSION.tiff]: "image/tiff",
  [FILE_EXTENSION.ts]: "application/typescript",
  [FILE_EXTENSION.ttf]: "font/ttf",
  [FILE_EXTENSION.txt]: "text/plain",
  [FILE_EXTENSION.vsd]: "application/vnd.visio",
  [FILE_EXTENSION.wav]: "audio/wav",
  [FILE_EXTENSION.weba]: "audio/webm",
  [FILE_EXTENSION.webm]: "video/webm",
  [FILE_EXTENSION.webp]: "image/webp",
  [FILE_EXTENSION.wmv]: "video/x-ms-wmv",
  [FILE_EXTENSION.woff]: "font/woff",
  [FILE_EXTENSION.woff2]: "font/woff2",
  [FILE_EXTENSION.xhtml]: "application/xhtml+xml",
  [FILE_EXTENSION.xls]: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  [FILE_EXTENSION.xlsx]: "application/vnd.ms-excel",
  [FILE_EXTENSION.xml]: "application/xml",
  [FILE_EXTENSION.zip]: "application/zip",
};

export enum FILE_MIME_TYPE {
  doc = "application/msword",
  docx = "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  pdf = "application/pdf",
  xls = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  xlsx = "application/vnd.ms-excel",
  jpg = "image/jpeg",
  png = "image/png",
  gif = "image/gif",
  webm = "video/webm",
  mp4 = "video/mp4",
}

export enum FILE_TYPE {
  IMAGE = "image",
  VIDEO = "video",
  COMPRESSED = "compressed",
  PDF = "pdf",
  WORD = "word",
  EXCEL = "excel",
}

export const FILE_TYPE_BY_EXTENSION: Partial<Record<FILE_EXTENSION, FILE_TYPE>> = {
  [FILE_EXTENSION.jpg]: FILE_TYPE.IMAGE,
  [FILE_EXTENSION.gif]: FILE_TYPE.IMAGE,
  [FILE_EXTENSION.tiff]: FILE_TYPE.IMAGE,
  [FILE_EXTENSION.png]: FILE_TYPE.IMAGE,
  [FILE_EXTENSION.svg]: FILE_TYPE.IMAGE,
  [FILE_EXTENSION.mp4]: FILE_TYPE.VIDEO,
  [FILE_EXTENSION.mpeg]: FILE_TYPE.VIDEO,
  [FILE_EXTENSION.mpg]: FILE_TYPE.VIDEO,
  [FILE_EXTENSION.avi]: FILE_TYPE.VIDEO,
  [FILE_EXTENSION.mkv]: FILE_TYPE.VIDEO,
  [FILE_EXTENSION.mov]: FILE_TYPE.VIDEO,
  [FILE_EXTENSION.wmv]: FILE_TYPE.VIDEO,
  [FILE_EXTENSION.flv]: FILE_TYPE.VIDEO,
  [FILE_EXTENSION.webm]: FILE_TYPE.VIDEO,
  [FILE_EXTENSION.zip]: FILE_TYPE.COMPRESSED,
  [FILE_EXTENSION.arj]: FILE_TYPE.COMPRESSED,
  [FILE_EXTENSION.tar]: FILE_TYPE.COMPRESSED,
  [FILE_EXTENSION.gzip]: FILE_TYPE.COMPRESSED,
  [FILE_EXTENSION["7z"]]: FILE_TYPE.COMPRESSED,
  [FILE_EXTENSION.pdf]: FILE_TYPE.PDF,
  [FILE_EXTENSION.doc]: FILE_TYPE.WORD,
  [FILE_EXTENSION.docx]: FILE_TYPE.WORD,
  [FILE_EXTENSION.xls]: FILE_TYPE.EXCEL,
  [FILE_EXTENSION.xlsx]: FILE_TYPE.EXCEL,
  [FILE_EXTENSION.csv]: FILE_TYPE.EXCEL,
};

export const FILE_TYPE_BY_MIME_TYPE: Record<string, FILE_TYPE> = {
  [FILE_MIME_TYPE.jpg]: FILE_TYPE.IMAGE,
  [FILE_MIME_TYPE.gif]: FILE_TYPE.IMAGE,
  [FILE_MIME_TYPE.png]: FILE_TYPE.IMAGE,
  [FILE_MIME_TYPE.pdf]: FILE_TYPE.PDF,
  [FILE_MIME_TYPE.doc]: FILE_TYPE.WORD,
  [FILE_MIME_TYPE.docx]: FILE_TYPE.WORD,
  [FILE_MIME_TYPE.xls]: FILE_TYPE.EXCEL,
  [FILE_MIME_TYPE.xlsx]: FILE_TYPE.EXCEL,
  [FILE_MIME_TYPE.mp4]: FILE_TYPE.VIDEO,
  [FILE_MIME_TYPE.webm]: FILE_TYPE.VIDEO,
};

export const KB_bytes = 1024;
export const MB_bytes = KB_bytes * 1024;
export const GB_bytes = MB_bytes * 1024;
