export const WHYUZ_FRONTEND_URL_HOME = "/";
export const WHYUZ_FRONTEND_URL_LOGIN = "/login";
export const WHYUZ_FRONTEND_URL_HELP = "/help";
export const WHYUZ_FRONTEND_URL_APPROVAL_FLOW = "/approval-flow-template";
export const WHYUZ_FRONTEND_URL_APPROVAL_FLOW_ID = `${WHYUZ_FRONTEND_URL_APPROVAL_FLOW}/:id`;
export const WHYUZ_FRONTEND_URL_APPROVAL_FLOWS = "/approval-flow-templates";
export const WHYUZ_FRONTEND_URL_APPROVAL_EXECUTIONS = "/approval-flow-executions";
export const WHYUZ_FRONTEND_URL_APPROVAL_EXECUTION = "/approval-flow-execution";
export const WHYUZ_FRONTEND_URL_APPROVAL_EXECUTION_ID = `${WHYUZ_FRONTEND_URL_APPROVAL_EXECUTION}/:id`;

export const WHYUZ_FRONTEND_URL_USER_APPROVAL = "/user-approval";
export const WHYUZ_FRONTEND_URL_USER_APPROVAL_ID = `${WHYUZ_FRONTEND_URL_USER_APPROVAL}/:id`;

export const WHYUZ_FRONTEND_URL_CAMPAIGN = "/campaign";
export const WHYUZ_FRONTEND_URL_CAMPAIGN_ID = `${WHYUZ_FRONTEND_URL_CAMPAIGN}/:id`;
export const WHYUZ_FRONTEND_URL_CAMPAIGNS = "/campaigns";
export const WHYUZ_FRONTEND_URL_COLLABORATION = "/collaboration";
export const WHYUZ_FRONTEND_URL_COLLABORATION_ID = `${WHYUZ_FRONTEND_URL_COLLABORATION}/:id`;
export const WHYUZ_FRONTEND_URL_COLLABORATIONS = "/collaborations";
export const WHYUZ_FRONTEND_URL_COLLABORATION_SUCCESS_CASE = "/collaborationsuccesscase";
export const WHYUZ_FRONTEND_URL_COLLABORATION_SUCCESS_CASE_ID = `${WHYUZ_FRONTEND_URL_COLLABORATION_SUCCESS_CASE}/:id`;
export const WHYUZ_FRONTEND_URL_CONTACT = "/contact";
export const WHYUZ_FRONTEND_URL_CONTACT_ID = `${WHYUZ_FRONTEND_URL_CONTACT}/:id`;
export const WHYUZ_FRONTEND_URL_CONTACTS = "/contacts";
export const WHYUZ_FRONTEND_URL_CONTENT = "/content";
export const WHYUZ_FRONTEND_URL_CONTENT_ID = `${WHYUZ_FRONTEND_URL_CONTENT}/:id`;
export const WHYUZ_FRONTEND_URL_CONTENTS = "/contents";
export const WHYUZ_FRONTEND_URL_CONTENT_TEMPLATE = "/contenttemplate";
export const WHYUZ_FRONTEND_URL_CONTENT_TEMPLATE_ID = `${WHYUZ_FRONTEND_URL_CONTENT_TEMPLATE}/:id`;
export const WHYUZ_FRONTEND_URL_CONTENT_TEMPLATES = "/contenttemplates";
export const WHYUZ_FRONTEND_URL_CUSTOMER = "/customer";
export const WHYUZ_FRONTEND_URL_CUSTOMER_ID = `${WHYUZ_FRONTEND_URL_CUSTOMER}/:id`;
export const WHYUZ_FRONTEND_URL_CUSTOMERS = "/customers";
export const WHYUZ_FRONTEND_URL_CORPORATION = "/corporation";
export const WHYUZ_FRONTEND_URL_CORPORATION_ID = `${WHYUZ_FRONTEND_URL_CORPORATION}/:id`;
export const WHYUZ_FRONTEND_URL_CORPORATIONS = "/corporations";
export const WHYUZ_FRONTEND_URL_CORPORATION_ADMINISTRATOR = "/corpadmin";
export const WHYUZ_FRONTEND_URL_DATABASE = "/database";
export const WHYUZ_FRONTEND_URL_DATABASE_ID = `${WHYUZ_FRONTEND_URL_DATABASE}/:id`;
export const WHYUZ_FRONTEND_URL_DATABASES = "/databases";
export const WHYUZ_FRONTEND_URL_MAIL_ACCOUNT = "/mailaccount";
export const WHYUZ_FRONTEND_URL_MAIL_ACCOUNT_ID = `${WHYUZ_FRONTEND_URL_MAIL_ACCOUNT}/:id`;
export const WHYUZ_FRONTEND_URL_MAIL_ACCOUNTS = "/mailaccounts";
export const WHYUZ_FRONTEND_URL_MAIL_TEMPLATE = "/mailtemplate";
export const WHYUZ_FRONTEND_URL_MAIL_TEMPLATE_ID = `${WHYUZ_FRONTEND_URL_MAIL_TEMPLATE}/:id`;
export const WHYUZ_FRONTEND_URL_MAIL_TEMPLATES = "/mailtemplates";
export const WHYUZ_FRONTEND_URL_REWARD_TEMPLATE = "/rewardtemplate";
export const WHYUZ_FRONTEND_URL_REWARD_TEMPLATE_ID = `${WHYUZ_FRONTEND_URL_REWARD_TEMPLATE}/:id`;
export const WHYUZ_FRONTEND_URL_REWARD_TEMPLATES = "/rewardtemplates";

export const WHYUZ_FRONTEND_URL_ASSIGNED_REWARD = "/assigned-reward";
export const WHYUZ_FRONTEND_URL_ASSIGNED_REWARD_ID = `${WHYUZ_FRONTEND_URL_ASSIGNED_REWARD}/:id`;
export const WHYUZ_FRONTEND_URL_ASSIGNED_REWARDS = "/assigned-rewards";
export const WHYUZ_FRONTEND_URL_TENANT = "/company";
export const WHYUZ_FRONTEND_URL_TENANT_ID = `${WHYUZ_FRONTEND_URL_TENANT}/:id`;
export const WHYUZ_FRONTEND_URL_TENANTS = "/companies";
export const WHYUZ_FRONTEND_URL_SUCCESS_CASE_CONTENT_EDITOR = "/succescasecontenteditor";
export const WHYUZ_FRONTEND_URL_SUCCESS_CASE_CONTENT_EDITOR_ID = `${WHYUZ_FRONTEND_URL_SUCCESS_CASE_CONTENT_EDITOR}/:id`;
export const WHYUZ_FRONTEND_URL_SUCCESS_CASE_TEMPLATE = "/successcasetemplate";
export const WHYUZ_FRONTEND_URL_SUCCESS_CASE_TEMPLATE_ID = `${WHYUZ_FRONTEND_URL_SUCCESS_CASE_TEMPLATE}/:id`;
export const WHYUZ_FRONTEND_URL_SUCCESS_CASE_TEMPLATES = "/successcasetemplates";
export const WHYUZ_FRONTEND_URL_SUCCESS_CASE = "/successcase";
export const WHYUZ_FRONTEND_URL_SUCCESS_CASE_ID = `${WHYUZ_FRONTEND_URL_SUCCESS_CASE}/:id`;
export const WHYUZ_FRONTEND_URL_SUCCESS_CASES = "/successcases";
export const WHYUZ_FRONTEND_URL_USER = "/user";
export const WHYUZ_FRONTEND_URL_USER_ID = `${WHYUZ_FRONTEND_URL_USER}/:id`;
export const WHYUZ_FRONTEND_URL_USERS = "/users";
export const WHYUZ_FRONTEND_URL_USER_LICENSE = "/userlicense";
export const WHYUZ_FRONTEND_URL_USER_LICENSE_ID = `${WHYUZ_FRONTEND_URL_USER_LICENSE}/:id`;
export const WHYUZ_FRONTEND_URL_USER_LICENSES = "/userlicenses";
