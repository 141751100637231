import approval from "./approval.json";
import benefitReward from "./benefitReward.json";
import campaign from "./campaign.json";
import chat from "./chat.json";
import collaboration from "./collaboration.json";
import common from "./common.json";
import contact from "./contact.json";
import content from "./content.json";
import contentItem from "./contentItem.json";
import corporation from "./corporation.json";
import country from "./country.json";
import customer from "./customer.json";
import database from "./database.json";
import entities from "./entities.json";
import firstLogin from "./firstLogin.json";
import instructions from "./instructions.json";
import language from "./language.json";
import login from "./login.json";
import mailAccount from "./mailAccount.json";
import mailTemplate from "./mailTemplate.json";
import notification from "./notification.json";
import region from "./region.json";
import screenAndCameraRecorder from "./screenAndCameraRecorder.json";
import successcasecontenteditor from "./successCaseContentEditor.json";
import successCaseTemplate from "./successCaseTemplate.json";
import tenant from "./tenant.json";
import textEditor from "./textEditor.json";
import user from "./user.json";
import usertenantrole from "./usertenantrole.json";
import videoPlayer from "./videoPlayer.json";
import videoRecorder from "./videoRecorder.json";

export const es = {
  approval,
  benefitReward,
  campaign,
  chat,
  collaboration,
  common,
  contact,
  content,
  contentItem,
  corporation,
  country,
  customer,
  database,
  entities,
  instructions,
  firstLogin,
  language,
  login,
  mailAccount,
  mailTemplate,
  notification,
  region,
  screenAndCameraRecorder,
  successcasecontenteditor,
  successCaseTemplate,
  tenant,
  textEditor,
  user,
  usertenantrole,
  videoPlayer,
  videoRecorder,
};
