// import all namespaces (for the default language, only)
import { resources } from "./resources";

export const DEFAULT_LOCALE = "en";

declare module "i18next" {
  interface CustomTypeOptions {
    defaultNS: "common";
    returnNull: false;
    fallbackLng: "en";
    resources: typeof resources.en;
  }
}
